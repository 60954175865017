import { LegoTheme } from 'legos/theme';

export const theme: LegoTheme = {
  neutral: {
    color50: '#fafafa',
    color100: '#f0f3f3',
    color200: '#d9e1e2',
    color300: '#c3c9ca',
    color400: '#adb1b3',
    color500: '#97999b',
    color600: '#797a7c',
    color700: '#53565a',
    color800: '#333f48',
    color900: '#1a2024'
  },
  default: {
    color50: '#e6f4f9',
    color100: '#cce9f2',
    color200: '#99d3e4',
    color300: '#66bed7',
    color400: '#33a8c9',
    color500: '#0092bc',
    color600: '#007596',
    color700: '#005871',
    color800: '#003a4b',
    color900: '#001d26'
  },
  accent: {
    color50: '#f6e6f0',
    color100: '#eccce1',
    color200: '#da99c2',
    color300: '#c766a4',
    color400: '#b53385',
    color500: '#a20067',
    color600: '#820052',
    color700: '#61003e',
    color800: '#410029',
    color900: '#200015'
  },
  positive: {
    color50: '#f3f9e6',
    color100: '#e6f2cc',
    color200: '#cee599',
    color300: '#b5d766',
    color400: '#9dca33',
    color500: '#84bd00',
    color600: '#6a9700',
    color700: '#4f7100',
    color800: '#354c00',
    color900: '#1a2600'
  },
  negative: {
    color50: '#fbe6eb',
    color100: '#f7ccd6',
    color200: '#ee99ad',
    color300: '#e66684',
    color400: '#dd335b',
    color500: '#d50032',
    color600: '#aa0028',
    color700: '#80001e',
    color800: '#550014',
    color900: '#2b000a'
  },
  special: {
    color50: '#fff3e6',
    color100: '#ffe6cc',
    color200: '#ffcd99',
    color300: '#ffb466',
    color400: '#ff9b33',
    color500: '#ff8200',
    color600: '#cc6800',
    color700: '#994e00',
    color800: '#663400',
    color900: '#331a00'
  }
};
